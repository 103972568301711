html,
body,
#root {
  height: 100%;
}

* {
  -webkit-tap-highlight-color: transparent;
}

img {  
  position: relative;
  font-size: 0;
}