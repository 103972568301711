@tailwind base;
@tailwind components;
@tailwind utilities;

body.embedded .embedded\:shadow-none {
    box-shadow: none;
}

body.embedded .embedded\:opacity-0 {
    opacity: 0;
}

body.embedded .embedded\:bg-white {
    background-color: #fff;
}

@media (prefers-color-scheme: dark) {
    body.embedded .embedded\:dark\:bg-slate-800 {
        background-color: #2d3748;
    }
}

@supports (-webkit-touch-callout: none) {
    .h-screen {
        height: -webkit-fill-available;
    }

    .min-h-screen {
        min-height: -webkit-fill-available;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}